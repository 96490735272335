<template>
	<div class="login-page">
		<div class="h-100 row">
			<div class="col-6 bg-primary d-flex flex-column justify-content-center align-items-center">
				<h1 class="h1 text-center text-white mb-4">Sign in</h1>
				<img width="300" src="@/assets/images/login.gif" alt="login image" />
			</div>

			<div class="col-6 d-flex justify-content-center align-items-center p-5">
				<div class="login-wrapper">
					<h1 class="h3 text-center text-primary mb-4">啟悅頻衡客戶管理系統</h1>
					<div class="mb-3">
						<label for="account" class="form-label">帳號</label>
						<input type="text" class="form-control" id="account" v-model="account" />
					</div>
					<div class="mb-5">
						<label for="password" class="form-label">密碼</label>
						<input type="password" class="form-control" id="password" v-model="password" />
					</div>

					<p v-if="invalidMsg.length > 0" class="text-danger text-center">{{ invalidMsg }}</p>
					<div class="d-grid">
						<button class="btn btn-info rounded-pill" @click="login()">登入</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Cookies from "js-cookie";
import { apiLogin } from "@/assets/js/api.js";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { ref } from "vue";
export default {
	name: "Login",
	setup() {
		const router = useRouter();
		const store = useStore();

		const account = ref("");
		const password = ref("");
		const invalidMsg = ref("");

		const setPageLoading = (isLoading) => store.dispatch("setPageLoading", isLoading);

		const login = () => {
			setPageLoading(true);

			invalidMsg.value = "";

			apiLogin({
				account: account.value,
				password: password.value,
			})
				.then((response) => {
					setPageLoading(false);
					if (response.data.status === "0") {
						Cookies.set("token", response.data.data.token);
						router.push({ name: "Client" });
					} else {
						invalidMsg.value = response.data.message;
					}
				})
				.catch(() => {
					setPageLoading(false);
				});
		};

		return {
			account,
			password,
			login,
			invalidMsg,
		};
	},
};
</script>

<style lang="scss" scoped>
.login-page {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
}
.login-wrapper {
	width: 100%;
	max-width: 300px;
	margin: auto;
}
</style>
